*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Sofia Pro", sans-serif;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  color: #000;
  font-size: 1rem;
  background-color: #f5f5f5;
}

@font-face {
  font-family: "Sofia Pro";
  src: local("Sofia Pro"), url(../fonts/sofiaPro.woff2) format("woff");
}

@font-face {
  font-family: "Druk Wide Bold";
  src: local("Druk Wide Bold"),
    url(../fonts/drukWideBold.ttf) format("truetype");
}

.container {
  max-width: 1600px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
  color: #000;
  &:hover {
    color: #6b9368;
  }
}

.slick-track {
  margin-left: unset;
}

.Product_slider {
  .slick-list {
    margin: 0 -12px;
  }

  .slick-slide > div {
    padding: 0 12px;
  }
}

input {
  font-size: 0.9375rem;
  font-weight: 500;
}

::selection {
  background-color: #6b9368;
  color: #fff;
}

::placeholder {
  color: #afa9a9 !important;
  font-weight: 400;
  font-family: "Sofia Pro", sans-serif;
}

// Custom scrollbar start
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #737373b3;
}

::-webkit-scrollbar-thumb:hover {
  background: #737373ff;
}
// Custom scrollbar end

// No scrollbar start
.no_scrollbar::-webkit-scrollbar {
  display: none;
}

.no_scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
// No scrollbar end

// No arrows start
.no_arrows::-webkit-outer-spin-button,
.no_arrows::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.no_arrows[type="number"] {
  appearance: textfield;
}
// No arrows end

// antd styles start
.ant-form-item-has-error {
  .ant-upload {
    border-color: #ff4d4f !important;
  }
}

.row_not_expandable {
  .ant-table-row-expand-icon {
    visibility: hidden;
  }
}

.ant-btn.has_error {
  border-color: #ff4d4f;
}
// antd styles end

@media (min-width: 768px) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
